export default class LicenseProvider
{
    ProvideLicense()
    {
        return {
            company: "Dun & Bradstreet Hungary Kft.",
            date: "01/07/2025",
            developer: "László Meidl (meidll@dnb.com)",
            distribution: true,
            domains: ["bisnode.hu"],
            fileSystemAllowed: false,
            licensefileversion: "1.1",
            localhost: false,
            oobAllowed: false,
            package: "complete",
            product: "yFiles for HTML",
            subscription: "12/19/2025",
            type: "singledeveloper",
            version: "2.6",
            key: "51d2f2aea5e13160f856622654567ad988b1ef57"
          };
    }
}